import React, { useState } from "react";
import Layout from '../components/Layout'
import { useForm } from "react-hook-form";
import styled from "styled-components"
import { device } from '../components/Device'
import emailjs from 'emailjs-com';
import emailJSConfig from '../utils/emailJSConfig'
import { init } from 'emailjs-com';
import Loading from '../components/Loading';

init("user_88YUa6DCEGdnoHYXNYIXB");


const Container = styled.div`
    margin: auto;
    width: 1024px;
    padding-top: 80px;

    @media ${device.laptop} {
        width: 768px;
    }

    @media ${device.tablet} {
        width: 425px;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
`

const Title = styled.h1`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  letter-spacing: -0.01em;
  text-align: center;
  /* Core / 100 */

  color: #3E3E3E;

  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
  margin-bottom: 40px;
`

const ContentDiv = styled.div`
/* P1 - Regular Text Body */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
/* or 150% */


/* Core / 80 */

color: #717171;
    p {
      margin: 16px 0;
    }

    strong {
      font-weight: 600;
    }

    li strong {
      color: #C8625B;
    }

    li {
      margin-top: 10px;
    }

    ul {
      list-style-type: circle;
      padding-left: 16px;
    }

    a {
      display: block;
      text-align: center;
      margin 5px 0;
      color: #C8625B;
    }
`

const FormContainer = styled.div`
    background: rgba(220, 220, 220, 0.6);
    width: 60%;
    padding: 40px 20%;

`

const FormGroup = styled.div`
  margin-bottom: 24px;
`


const Label = styled.label`
font-family: Google Sans;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Core / 100 */

color: #3E3E3E;
padding-bottom: 12px;
display: block;
`
const RadioButton = styled.input`
  border: none;
  width: 18px;
  border-radius: 4px;
`
const RadioLabel = styled.label`
/* P1 - Regular Text Body */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 25px;
/* identical to box height, or 150% */


/* Core / 100 */

color: #3E3E3E;
`
const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`


const Input = styled.input`
  background: #FFFFFF;
  border-radius: 8px;
  height: 53px;
  display: block;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;

/* Core / 60 */
border: none;
color: #3E3E3E;

  ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
    color: #A6A6A5;
  }
`

const Button = styled.input`
  height: 55px;
  background: #3e3e3e;
  color: white;
  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  border: none;
  border-radius: 4px;
  margin-top: 24px;
`

const Error = styled.span`
  color: red;
  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`

const TextArea = styled.textarea`
  width: 90%;
  background: #FFF;
  border-radius: 8px;

  font-family: Google Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
/* identical to box height, or 150% */
  padding-left: 5%;
  padding-right: 5%;

/* Core / 60 */
  border: none;
  color: #3E3E3E;
  padding-top: 5%;
  ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
    color: #A6A6A5;
  }
  resize: none;
`

export default function WeddingFormPage(props) {
  const { pageContext } = props
  const { register, handleSubmit, watch, errors, reset } = useForm();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(false)
    // if (data.document[0].size / 1000 > 500) {
    //   setLoading(false)
    //   alert('Tamanho do arquivo excede o permitido de 500kb')
    //   return
    // }
    // console.log(data.document[0].size)
    // toBase64(data.document[0])
    // .then(file => {
      
      emailjs.send(emailJSConfig.serviceId, emailJSConfig.templateId.wedding, {
        wedding: data.wedding,
        regime: data.regime,
        groom: data.groom,
        bride: data.bride,
        mail: data.mail,
        phone: data.phone,
        witnesses: data.witnesses,
        address: data.address || "Não informado",
        reply_to: data.mail
      }).then(() => {
        setLoading(false)
        alert('Mensagem enviada com sucesso!')
        reset()
      }, () => {
        setLoading(false)
        alertError();  
      })
    // }, error => {
    //   setLoading(false)
    //   alertError();
    // })
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const alertError = () => {
    alert("Ocorreu um erro, tente novamente mais tarde.")
  }

  return (
    <Layout>
      <Container>
        <Title>{pageContext.title}</Title>
        <ContentDiv dangerouslySetInnerHTML={{ __html: pageContext.content }} />

        <FormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>Tipo de Casamento *</Label>
              <RadioGroup>
                <div>
                  <RadioButton name="wedding" ref={register({ required: true })} type="radio" value="civil-cartorio" />
                  <RadioLabel>Civil - No Cartório</RadioLabel>
                </div>

                <div>
                  <RadioButton name="wedding" ref={register({ required: true })} type="radio" value="religioso" />
                  <RadioLabel>Religioso Com Efeito Civil (na igreja)</RadioLabel>
                </div>

                <div>
                  <RadioButton name="wedding" ref={register({ required: true })} type="radio" value="civil-residencia" />
                  <RadioLabel>Civil - Em Residência</RadioLabel>
                </div>

              </RadioGroup>
              {errors.wedding && <Error>Este campo é obrigatório</Error>}
            </FormGroup>

            <FormGroup>
              <Label>Regime de Bens a ser Adotado *</Label>
              <RadioGroup>
                <div>
                  <RadioButton name="regime" ref={register({ required: true })} type="radio" value="parcial-de-bens" />
                  <RadioLabel>Comunhão Parcial de Bens</RadioLabel>
                </div>

                <div>
                  <RadioButton name="regime" ref={register({ required: true })} type="radio" value="universal" />
                  <RadioLabel>Comunhão Universal</RadioLabel>
                </div>

                <div>
                  <RadioButton name="regime" ref={register({ required: true })} type="radio" value="final-dos-asquestos" />
                  <RadioLabel>Participação Final dos Aquestos</RadioLabel>
                </div>
                
                <div>
                  <RadioButton name="regime" ref={register({ required: true })} type="radio" value="separacao-de-bens" />
                  <RadioLabel>Separação de Bens</RadioLabel>
                </div>
              </RadioGroup>
              {errors.regime && <Error>Este campo é obrigatório</Error>}
            </FormGroup>

            <FormGroup>
              <Label>Nome completo do noivo(o)*</Label>
              <Input name="groom" placeholder="Digite o nome" ref={register({ required: true })} type="text" />
              {errors.groom && <Error>Este campo é obrigatório</Error>}
            </FormGroup>

            <FormGroup>
              <Label>Nome completo da noivo(a)*</Label>
              <Input name="bride" placeholder="Digite o nome" ref={register({ required: true })} type="text" />
              {errors.bride && <Error>Este campo é obrigatório</Error>}
            </FormGroup>

            <FormGroup>
              <Label>E-mail* </Label>
              <Input name="mail" placeholder="Digite seu e-mail" type="mail" ref={register} />
              {errors.mail && <Error>Este campo é obrigatório</Error>}
            </FormGroup>

            <FormGroup>
              <Label>Telefone *</Label>
              <Input name="phone" placeholder="(00) 00000-0000" type="phone" ref={register({ required: true })} />
              {errors.phone && <Error>Este campo é obrigatório</Error>}
            </FormGroup>

            <FormGroup>
              <Label>Nome completo das testemunhas* </Label>
              <Input name="witnesses" placeholder="Digite os nomes" ref={register({ required: true })} type="text" />
              {errors.witnesses && <Error>Este campo é obrigatório</Error>}
            </FormGroup>

            <FormGroup>
              <Label>Endereço Completo dos Noivos (Somente Distrito Federal)</Label>
              <Input name="address" placeholder="Digite o endereço" ref={register({ required: true })} type="text" />
            </FormGroup>
{/* 
            <FormGroup>
                  <Label>Observações</Label>
                  <TextArea rows="7" name="message" placeholder="Informe aqui qual música poderá ser tocada no dia da cerimônia, nome completo dos pais dos noivos, bem como outras obvervações." ref={register({ required: true })} />
                  {errors.message && <Error>Este campo é obrigatório</Error>}
            </FormGroup> */}
            {/* 
            <FormGroup>
              <Label>Documentação (arquivo de no máximo 500kb)</Label>
              <input name="document" placeholder="Enviar arquivo" ref={register({ required: true })} type="file" />
              {errors.document && <Error>Este campo é obrigatório</Error>}
            </FormGroup> */}
              <Label>Responderemos em até 3 dias úteis.</Label>
             {isLoading ? <Loading /> : <Button type="submit" />}
             
          </form>
        </FormContainer>
      </Container>
    </Layout>
  );
}